<template>
  <div>
    <action-header
      :is-loading="!event"
      :actions-model="headerModel"
      :page="page"
    />

    <div class="q-pa-md">
      <form-builder :schema="schema" />

      <q-table
        v-if="Array.isArray((event || {}).fields)"
        row-key="extId"
        class="full-width q-ma-sm"
        :rows-per-page-label="$t('Rows per page')"
        :rows="event.fields"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: event.fields.length }"
        virtual-scroll
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td
              key="property"
              :props="props"
            >
              <q-select
                standout="bg-teal text-white"
                :model-value="props.row.property"
                :options="propertyOptions"
                :label="$t('Property')"
              >
                <template
                  v-slot:option="{ opt }"
                >
                  <div
                    class="q-pa-sm card--clickable card--clickable-no-scale"
                    @click="updateRow(props.rowIndex, 'property', opt)"
                  >
                    {{ opt }}
                  </div>
                </template>
              </q-select>
            </q-td>

            <q-td
              key="operator"
              :props="props"
            >
              <q-select
                standout="bg-teal text-white"
                :model-value="props.row.operator"
                :options="operators"
                :label="$t('Operator')"
              >
                <template
                  v-slot:option="{ opt }"
                >
                  <div
                    class="q-pa-sm card--clickable card--clickable-no-scale"
                    @click="updateRow(props.rowIndex, 'operator', opt)"
                  >
                    {{ opt }}
                  </div>
                </template>
              </q-select>
            </q-td>

            <q-td
              key="value"
              :props="props"
            >
              <q-input
                standout="bg-teal text-white"
                type="text"
                :label="$t('Value')"
                :model-value="props.row.value"
                required
                @update:model-value="updateRow(props.rowIndex, 'value', $event)"
              />
            </q-td>

            <q-td
              key="actions"
              :props="props"
            >
              <q-btn
                color="negative"
                text-color="white"
                icon="delete"
                no-caps
                unelevated
                @click="removeRow(props.rowIndex)"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>

      <div class="q-py-sm">
        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Add row')"
          class="full-width"
          no-caps
          unelevated
          @click="addRow"
        />
      </div>
    </div>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from './../../components/action-header/ActionHeader'

export default {
  name: 'Event',
  components: {
    ActionHeader
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      propertyOptions: [
        'deliveryService',
        'state'
      ],
      operators: [
        '=',
        '!=',
        'in'
      ],
      columns: [
        {
          label: this.$t('Property'),
          name: 'property'
        },
        {
          label: this.$t('Operator'),
          name: 'operator'
        },
        {
          label: this.$t('Value'),
          name: 'value'
        },
        {
          label: this.$t('Actions'),
          name: 'actions'
        }
      ],
      states: [
        { id: 'pending', name: this.$t('Pending') },
        { id: 'prepared', name: this.$t('Prepared') },
        { id: 'sent', name: this.$t('Sent') },
        { id: 'delivered', name: this.$t('Delivered') },
        { id: 'error', name: this.$t('Error') },
        { id: 'deleted', name: this.$t('Deleted') }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'event'
    ]),
    schema () {
      return {
        isLoading: !this.event,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Entity'),
                field: 'entity',
                value: this.event && this.event.entity,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: entity => {
                  this.updateEvent({ entity })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Entity Type'),
                field: 'entityType',
                value: this.event && this.event.entityType,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: entityType => {
                  this.updateEvent({ entityType })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Event Initializer'),
                field: 'eventInitializer',
                value: this.event && this.event.eventInitializer,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: eventInitializer => {
                  this.updateEvent({ eventInitializer })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Result'),
                field: 'result',
                value: this.event && this.event.result,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: result => {
                  this.updateEvent({ result })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Template'),
                field: 'template',
                value: this.event && this.event.template,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: template => {
                  this.updateEvent({ template })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Status'),
                field: 'state',
                value: this.event && this.event.state,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.states,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (state) => {
                  this.updateEvent({ state: state.id })
                  this.hasChange = true
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Channel'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                field: 'channel',
                value: this.event && this.event._embedded.channel,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.notificationChannel.getAll(query)
                },
                onChange: channel => {
                  this.updateEventEmbedded({ channel })
                  this.hasChange = true
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Task'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                field: 'task',
                value: this.event && this.event._embedded.task,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.notificationTask.getAll(query)
                },
                onChange: task => {
                  this.updateEventEmbedded({ task })
                  this.hasChange = true
                }
              }
            ]
          }
        ]
      }
    },
    page () {
      return {
        id: this.event && this.event.id,
        name: this.event && this.event.id
          ? this.$t('Event')
          : this.$t('New event')
      }
    },
    headerModel () {
      if (!this.event) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col q-px-xs text-white mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              value: this.event && this.event.id
                ? this.$t('Event ID: ') + this.event.id
                : this.$t('New Event')
            }
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.$route.params.id && !this.event) {
      this.loadEvent(this.$route.params.id)
    }

    if (!this.$route.params.id) {
      this.setNewEvent()

      const owner = JSON.parse(localStorage.getItem('userData'))
      this.updateEvent({
        _embedded: {
          ...this.event._embedded,
          owner
        }
      })
    }
  },
  unmounted () {
    this.setEvent(null)
  },
  methods: {
    ...mapActions([
      'loadEvent',
      'saveEvent'
    ]),
    ...mapMutations([
      'setNewEvent',
      'setEvent',
      'updateEvent',
      'updateEventEmbedded'
    ]),
    refresh () {
      this.loadEvent(this.$route.params.id)
    },
    updateRow (index, key, value) {
      this.hasChange = true
      this.updateEvent({
        fields: this.event.fields.map((field, i) => {
          if (i === index) {
            const newField = {...field}
            newField[key] = value
            return newField
          }

          return field
        })
      })
    },
    removeRow (index) {
      this.hasChange = true
      this.updateEvent({
        fields: [
          ...this.event.fields.slice(0, index),
          ...this.event.fields.slice(index + 1)
        ]
      })
    },
    addRow () {
      this.hasChange = true
      this.updateEvent({
        fields: [
          ...this.event.fields,
          { property: '', operator: '', value: '' }
        ]
      })
    },
    save () {
      this.isSaveLoading = true
      this.saveEvent()
        .then(() => {
          this.hasChange = false
          if (!this.event.id) {
            this.handleBack()
          }
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    }
  }
}
</script>
